// react
import React, { createRef, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Image from "react-bootstrap/Image"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Table from 'react-bootstrap/Table'
// internal modules
import Layout from 'components/layout'
import MCOLogo from 'images/tipexampleall.png'
import SingleTip from 'images/tiptracesingle.png'


const ReportTestPage = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
      <>
        <Container>
          <Row>
            <Col>
              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Home">
                  <Card>
                    <Card.Img src={MCOLogo} onClick={handleShow}/>
                  </Card>
                </Tab>
                <Tab eventKey="profile" title="Profile">
                  <Card>
                    <Card.Img src={MCOLogo} onClick={handleShow}/>
                  </Card>
                </Tab>
                <Tab eventKey="contact" title="Contact">
                  <Card>
                    <Card.Img src={MCOLogo} onClick={handleShow}/>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Trace #</th>
                    <th>Location</th>
                    <th>CMS Shift</th>
                    <th>BW Shift</th>
                    <th>
                      Uncertainty
                    </th>
                    <th>Pre-Shift</th>
                    <th>CMS</th>
                    <th>BW</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>16-9</td>
                    <td>-2</td>
                    <td>-1</td>
                    <td>±0.8</td>
                    <td>1.2%</td>
                    <td>6.0%</td>
                    <td>3.1%</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>24-9</td>
                    <td>+4</td>
                    <td>+1</td>
                    <td>±0.7</td>
                    <td>2.2%</td>
                    <td>1.1%</td>
                    <td>2.6%</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>32-9</td>
                    <td>+3</td>
                    <td>-2</td>
                    <td>±0.2</td>
                    <td>0.1%</td>
                    <td>2.1%</td>
                    <td>1.4%</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>40-9</td>
                    <td>-2</td>
                    <td>+3</td>
                    <td>±1.6</td>
                    <td>1.1%</td>
                    <td>2.0%</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>48-9</td>
                    <td>+2</td>
                    <td>+1</td>
                    <td>±1.4</td>
                    <td>1.3%</td>
                    <td>0.8%</td>
                    <td>0.6%</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>8-17</td>
                    <td>+3</td>
                    <td>-1</td>
                    <td>±1.1</td>
                    <td>3.2%</td>
                    <td>1.9%</td>
                    <td>1.3%</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <Modal size='lg' show={show} onHide={handleClose}>
          <Modal.Body>
            <Card>
              <Card.Img as={Image} fluid={true} src={SingleTip}/>
            </Card>
          </Modal.Body>
        </Modal>
      </>
  )
}

ReportTestPage.Layout = Layout

export default ReportTestPage
